<template>
  <section id="publications">
    <h1>Highlights</h1>
    <div class="videos">
        <iframe width="48%" height="315"
        src="https://www.youtube.com/embed/e6gXoMA5te4">
        </iframe>
        <iframe width="48%" height="315"
        src="https://www.youtube.com/embed/RBYFsFr4soo">
        </iframe>
    </div>

    <h1>Publications</h1>
    <div v-for="pub in publications" :key="pub.title" class="publication-container">
        <div class="publication-info">
            <a :href="pub.publicationLink" target="_blank"><h4> <i class="fas fa-file"></i> {{pub.title}} </h4></a>
            <p class="authors"> {{pub.authors}} </p>
            <p class="authors"> {{pub.conference}} </p>
            <p class="abstract"> {{truncate(pub.abstract)}} </p>
            <a :href="pub.github" class="pub-link" v-if="pub.github" target="_blank">
                <i class="fab fa-github"></i>
            </a>
        </div>

        <div class="thumbnail" v-if="pub.youtubeId">
            <a :href="`https://www.youtube.com/embed/${pub.youtubeId}`" target="_blank">
            <i class="fas fa-play"></i>
                <img :src="`//img.youtube.com/vi/${pub.youtubeId}/0.jpg`">
            </a>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  data() {
      return {
          publications: []
      }
  },
  created() {
      axios.get('publications.json').then(response => {
          this.publications = response.data.publications;
     })
  },
  methods: {
      truncate(text) {
          const trunc = `${text.slice(0, 450)}...`;
          return trunc;
      }
  }
}
</script>

<style lang="scss" scoped>
$main-color: #4d8a77;
.videos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
#publications {
    padding: 60px 20px 40px;
    border-bottom: 1px solid black;
}
h1 {
    margin-bottom: 30px;
}
.publication-container {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 38px;
    border-bottom: 1px solid #d4d0d0;
    &:last-child {
        padding: 0;
        margin: 0;
        border: none;
    }
    .thumbnail {
        width: 250px;
        img {
            width: 100%;
            -webkit-box-shadow: -1px 2px 7px -1px rgba(0,0,0,0.16);
            -moz-box-shadow: -1px 2px 7px -1px rgba(0,0,0,0.16);
            box-shadow: -1px 2px 7px -1px rgba(0,0,0,0.16);
            opacity: .8;
            &:hover {
                opacity: 1;
            }
        }
        i {
            position: absolute;
            margin-left: 109px;
            font-size: 35px;
            color: #4e4d4d;
            margin-top: 67px;
            z-index: 100;
            opacity: .85;

        }
    }
    .publication-info {
        width: 75%;
        margin-right: 5%;
        h4 {
            margin: 0;
        }
        p {
            font-size: .9em;
            &.authors{
                color: $main-color;
                font-style: italic;
                margin: 10px 0;
            }
        }
        i {
            color: $main-color;
            font-size: 1.2em;
            margin-right: 5px;

        }
        a {
            color: black;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .publication-container {
        flex-direction: column;
        .publication-info {
            width: 100%;
        }
    }
}
</style>
