<template>
  <section id="cv">
      <h1>CV!</h1>

      <div class="section-container">
        <div class="col">
          <div class="section">
            <h2> <i class="fas" :class="`fa-${sections[0].icon}`"></i> {{sections[0].title}} </h2>
            <div v-for="(item, i) in sections[0].items" :key="i">
              <h3> {{item.title}} </h3>
              <p> {{item.location}} </p>
              <p class="date"> {{item.date}} </p>
              <ul>
                <li v-for="(b,i) in item.bullets" :key="i"> {{b}} </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <h2> <i class="fas" :class="`fa-${sections[1].icon}`"></i> {{sections[1].title}} </h2>
            <div v-for="(item, i) in sections[1].items" :key="i">
              <h3> {{item.title}} </h3>
              <p> {{item.location}} </p>
              <p class="date"> {{item.date}} </p>
              <ul>
                <li v-for="(b,i) in item.bullets" :key="i"> {{b}} </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="section">
            <h2> <i class="fas" :class="`fa-${sections[2].icon}`"></i> {{sections[2].title}} </h2>
            <div v-for="(item, i) in sections[2].items" :key="i">
              <h3> {{item.title}} </h3>
              <p> {{item.location}} </p>
              <p class="date"> {{item.date}} </p>
              <ul>
                <li v-for="(b,i) in item.bullets" :key="i"> {{b}} </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <h2> <i class="fas" :class="`fa-${sections[3].icon}`"></i> {{sections[3].title}} </h2>
            <div v-for="(item, i) in sections[3].items" :key="i">
              <h3> {{item.title}} </h3>
              <p> {{item.location}} </p>
              <p class="date"> {{item.date}} </p>
              <ul>
                <li v-for="(b,i) in item.bullets" :key="i"> {{b}} </li>
              </ul>
            </div>
          </div>
        </div>

          
      </div>
  </section>
</template>

<script>

export default {
  name: 'CV',
  data() {
    return {
      sections: [
        {
          title: 'Currently',
          icon: 'briefcase',
          items: [
            {
              title: 'Research Associate',
              location: 'Applied Mathematics, University of Washington, Seattle, USA',
              date: 'since 06/2019',
              bullets: [
                'Basic research in series expansions in context of Machine Learning',
                'Time: Fourier- and Koopman-based long-term time series prediction',
                'Space: Taylor-based solvers for fast rendering'
              ]
            }
          ]
        },
        {
          title: 'Education',
          icon: 'university',
          items: [
            {
              title: 'Advanced Infrastructure Systems (PhD)',
              location: 'Carnegie Mellon University, Pittsburgh, USA',
              date: '01/2016 - 02/2019',
              bullets: [
                'Machine Learning applications to energy efficiency: Energy Disaggregation / Optimal Power Flow',
                'Thesis: Variational Methods for Energy Systems'
              ]
            },
            {
              title: 'Machine Learning and Data Mining (MSc)',
              location: 'Aalto University, Helsinki, Finland',
              date: '09/2012 - 12/2015',
              bullets: [
                'Special interests: Deep Learning, Natural Language Processing',
                'Thesis: Disaggregation by state-inference: A probabilistic framework for Non-intrusive load monitoring',
              ]
            },
            {
              title: 'Cognitive Science (BSc)',
              location: 'Universität Osnabrück, Germany',
              date: '10/2008 - 03/2012',
              bullets: [
                'Special interests: Artificial Intelligence, Machine Learning, Computational Linguistics',
                'Thesis: Predicting time series with reservoir computers using a delay-coupled non-linear system',
                'graduated with distinction',
              ]
            }
          ]
        },
        {
          title: 'Experience',
          icon: 'cog',
          items: [
            {
              title: 'Bbox Ltd',
              location: 'Data Analyst Intern',
              date: '10/2015 – 12/2015 | London, UK',
              bullets: [
                'Data Analytics for off-grid smart solar systems in Africa',
              ]
            },
            {
              title: 'SmartB Energy Management GmbH',
              location: 'Machine Learning & Software Engineering Working Student',
              date: '06/2014 – 02/2015 | Berlin, Germany',
              bullets: [
                'High frequency data collection',
                'Conceptualization of source seperation algorithms',
              ]
            },
            {
              title: 'Aalto University',
              location: 'Honorary Student / Research Assistant To Dr. Vigario',
              date: '10/2012 – 05/2013 | Helsinki, Finland',
              bullets: [
                'Blind source seperation',
              ]
            },
            {
              title: 'Teaching Assistant',
              location: 'University of Osnabrück / Carnegie Melon University',
              date: 'Osnabrück, Germany | Pittsburgh, USA',
              bullets: [
                'Undergrad: Introduction to Logic, Introduction to Artificial Intelligence & Logic Programming, Computer Vision',
                'Graduate: Data Driven Building Energy Management, Data Acquisition'
              ]
            },
          ]
        },
        {
              title: 'Skills',
              icon: 'lightbulb',
              items: [
                {
                title: 'Programming',
                location: 'In order of familiarity: Python, Java, Matlab, Prolog, C++, Android'
              },
              {
                title: 'Languages',
                location: 'Fluent in German and English, Rudimentary in French and Bulgarian'
              },
              ]
            }
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
$main-color: #4d8a77;
#cv {
  padding: 60px 20px 40px;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 20px;
  .section {
    margin-bottom: 40px;
  }
  .col {
    width: 46%;
  }
}

h2 {
  color: $main-color;
  i {
    margin-right: 5px;
  }
}

h3 {
  margin-bottom: 5px;
  font-weight: 500;
}

p {
  margin: 0;
  &.date {
    color: grey;
  }
}

ul {
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .section-container {
    margin: 0;
    .col {
      width: 100%;
    }
  }
  
}
</style>
