<template>
  <section id="admin">
      <h1>Admin</h1>

      <div class="admin-row">
        <div class="form">
          <p>Title</p>
          <input v-model="newPub.title" type="text">

          <p>Publication link</p>
          <input v-model="newPub.publicationLink" type="text">

          <p>Authors</p>
          <input v-model="newPub.authors" type="text">

          <p>Conference</p>
          <input v-model="newPub.conference" type="text">

          <p>Youtube ID</p>
          <input v-model="newPub.youtubeId" type="text">

          <p>Github link</p>
          <input v-model="newPub.github" type="text">

          <p>Abstract</p>
          <textarea v-model="newPub.abstract"></textarea>
          <br/>
          <button @click="addPublication">Add Publication</button>
        </div>

        <div class="output">
          <pre>{{newPubs}}</pre>
        </div>
      </div>



  </section>
</template>

<script>
// import axios from 'axios'
// const fs = require('fs');

export default {
  name: 'Admin',
  data() {
    return {
      newPubs: [],
      newPub: {
          title: '',
          authors: '',
          conference: '',
          publicationLink: '',
          youtubeId: '',
          github: '',
          abstract: ''
      }
    }  
  },
  methods: {
    // async addPublication() {
    //     const data = JSON.stringify(this.newPub);
    //     fs.writeFileSync('publications.json', data);
    // },
    addPublication() {
      this.newPubs.push(this.newPub);
      this.newPub = {
          title: '',
          authors: '',
          publicationLink: '',
          youtubeId: '',
          github: '',
          abstract: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#admin {
    padding: 50px;
}
h1 {
    margin-bottom: 20px;
}
p {
    margin-bottom: 5px;
    margin-top: 25px;
    width: 200px;
    font-weight: bold;
}
button {
    border: none;
    background: #03ad00;
    opacity: .9;
    color: white;
    padding: 10px 15px;
    border-radius: 7px;
    margin-top: 25px;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}
.admin-row {
  display: flex;
  > div {
    width: 50%;
  }
}
</style>
