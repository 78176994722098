
<template>
  <div id="app">
    <div v-if="isAdmin" class="main-container">
      <Admin />
    </div>
    
    <div v-else class="main-container">
      <Nav :isMobile="isMobile" :topPositions="topObj" />
      <Home :isMobile="isMobile" ref="home" />
      <Publications ref="publications" />
      <CV ref="cv"/>
    </div>
  </div>
</template>

<script>
import Nav from './components/Nav.vue';
import Home from './views/Home.vue';
import Publications from './views/Publications.vue';
import CV from './views/CV.vue';
import Admin from './views/Admin.vue';

export default {
  name: 'app',
  components: {
    Nav,
    Home,
    Publications,
    CV,
    Admin
  },
  data() {
    return {
      isMobile: false,
      topObj: [],
    }
  },
  mounted() {
    // Register an event listener when the Vue component is ready
    window.addEventListener('resize', this.onResize)
    this.onResize();
    
    setTimeout(() => {
      this.topObj = ['home', 'publications', 'cv'].map(ref => {
        return {
          id: ref, 
          top: this.$refs[ref].$el.offsetTop,
          height: this.$refs[ref].$el.clientHeight
        }
      });

    }, 200)
    
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    isAdmin() {
      return this.$route.path === '/admin';
    },
  },
  methods: {
    onResize() {
      this.isMobile =  window.innerWidth < 601;
    }
  }
}
</script>

<style lang="scss">

body {
  margin: 0;
}
#app {
  font-family: 'Roboto', sans-serif;
  background-image: url("./assets/grid.jpg");
  background-color: #e0e0e0;
  height: 100%;
  width: 100%;
  min-height: 1000px;
  display: flex;
}
.main-container {
  background: white;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
h1 {
  font-family: 'Roboto Slab', serif;
  margin: 0;
  font-size: 2.3em;
}
a {
  text-decoration: none;
}
</style>