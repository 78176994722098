<template>
  <section id="home" >
    <div class="profile-img">
      <div>
        <!-- mobile -->
        <h1 v-if="isMobile">Henning Lange, PhD</h1>
        <p v-if="isMobile" class="byline"> Postdoctoral Scholar at University of Washington</p>
      </div>
      <img src="../assets/profile.jpg" alt="henning lange profile pic" >
    </div>

    <div class="profile-text">
      <h1 v-if="!isMobile">Henning Lange, PhD</h1>
      <p v-if="!isMobile" class="byline"> Postdoctoral Scholar at University of Washington</p>

      <p>
        I am interested in fast algorithms and series expansions (Fast Fourier Transform, Fast Multipole Method, …) in the context of 
        Machine Learning. I talk about some ideas that explore applications of Fast Fourier Transform for time series prediction in the 
        Koopman framework on the 
        <a href="https://open.spotify.com/episode/3rytFydSvyju2pdAa8rw3N?si=9de8af4b8f8e4333" target="_blank" class="podcast-link" alt="henning lange podcast link">
        Data Skeptic Podcast (with Alex)</a>. 
        While this line of research tackles the temporal axis by the means of 
        Fourier expansions, I recently released a paper that explores ideas from the Fast Multipole Method applied to space. The resulting
        technique can be understood as the Taylor-equivalent to the FFT. By expanding a convolutional operator, we can reduce the number 
        of FLOPs required to train implicit models for Computer Vision 150-200x compared to Neural Network approaches.
      </p>

      <div class="social-media">
        <a href="https://github.com/helange23" class="social-link" target="_blank">
          <i class="fab fa-github"></i>
        </a>
        <a href="https://www.linkedin.com/in/henning-lange-33b701b6/" class="social-link" target="_blank">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://scholar.google.com/citations?user=-cRDOvAAAAAJ" class="social-link" target="_blank">
          <i class="fab fa-google"></i>
        </a>

        <a href="https://open.spotify.com/episode/3rytFydSvyju2pdAa8rw3N?si=9de8af4b8f8e4333" class="social-link" target="_blank">
          <i class="fab fa-spotify"></i>
        </a>

        <div class="social-link">
          <i class="fas fa-envelope"></i>
          <span>helange23@gmail.com</span>
        </div>
      </div>

      <!-- <iframe src="https://open.spotify.com/embed/episode/3rytFydSvyju2pdAa8rw3N?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe> -->
    </div>
  </section>
</template>

<script>

export default {
  name: 'Home',
  props: ['isMobile'],
}
</script>

<style lang="scss" scoped>
$main-color: #4d8a77;
#home {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-top: 65px;
}
.profile-img {
  width: 48%;
  margin-top: -65px;
  img {
    width: 100%;
  }
}
.profile-text {
  width: 45%;
  padding-right: 20px;
  p {
    line-height: 1.6em;
  }
}
.byline {
  font-style: italic;
  margin: 0 0 10px;
  color: $main-color;
}

.podcast-link {
  color: #0991e2;
  font-weight: bold;
  text-decoration: underline;
}

.social-media {
  display: flex;
  .social-link{
    margin-right: 20px;
  }
  .social-link {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      font-size: 0.8em;
      // color: $main-color;
    }
  }
  i {
    font-size: 1.2em;
    color: $main-color;
  }
}

@media only screen and (max-width: 768px) {
  .profile-text {
    padding-bottom: 20px;
  }
  .profile-img {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 601px) {
  #home {
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
    margin-top: 50px;
  }
  .profile-text, .profile-img{
    width: 100%;
  }
}
</style>
