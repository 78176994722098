<template>
  <div>
      <div id="nav" :class="{isScrolled}">
          <div>
              <h3 :class="{isScrolled}">Henning Lange PhD</h3>
          </div>
          <div v-if="!isMobile" class="menu">
            <a href="#home" :class="{active: activeSection === 'home'}">Home</a>
            <a href="#publications" :class="{active: activeSection === 'publications'}">Publications</a>
            <a href="#cv" :class="{active: activeSection === 'cv'}">CV</a>
            <!-- <a href="#blog">Blog</a> -->
          </div>

          <div v-else>
            <button class="menu-btn" @click="menuOpen = !menuOpen">
              <i class="fas fa-bars"></i>
            </button>
            <div class="mobile-menu" :class="{'open': menuOpen}">
              <a href="#home" @click="menuOpen = false">Home</a>
              <a href="#publications" @click="menuOpen = false">Publications</a>
              <a href="#cv" @click="menuOpen = false">CV</a>
              <!-- <a href="#blog" @click="menuOpen = false">Blog</a> --> 
            </div>
          </div>
      </div>

  </div>
</template>

<script>

export default {
  name: 'Nav',
  props: ['isMobile', 'topPositions'],
  data() {
      return {
          isScrolled: false,
          menuOpen: false,
          activeSection: 'home'
      }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 40) {
          this.isScrolled = true;
      } else {
          this.isScrolled = false;
      }

      this.topPositions.forEach(section => {
        const p = window.pageYOffset;
        // console.log(p , section.top,(section.top + section.height))
        if (p > section.top - 100 && p < (section.top + section.height)) {
          this.activeSection = section.id;
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
$main-color: #4d8a77;
#nav {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;;
  position: fixed;
  max-width: 1000px;
  padding: 15px 0;
  transition: 300ms ease all;
  a {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
  .menu {
    a {
      color: black;
      margin: 0 14px;
      border-bottom: 2px solid white;
      
      &.active {
        color: $main-color;
        border-bottom: 2px solid $main-color;
      }
    }
  }
  &.isScrolled {
    background: white;
    -webkit-box-shadow: -1px 2px 7px -1px rgba(0,0,0,0.16);
    -moz-box-shadow: -1px 2px 7px -1px rgba(0,0,0,0.16);
    box-shadow: -1px 2px 7px -1px rgba(0,0,0,0.16);
  }
}

h3 {
    color: transparent;
    margin: 0 0 0 20px;
    font-family: 'Roboto Slab', serif;
    &.isScrolled {
        color: black;
    }
}

.menu-btn {
  outline: none;
  border: none;
  background: none;
  margin-right: 20px;
  i {
    font-size: 24px;
  }
}

.mobile-menu {
  position: absolute;
  background: black;
  display: flex;
  flex-direction: column;
  right: 0;
  margin-top: 16px;
  max-height: 0;
  transition: 250ms ease all;
  overflow: hidden;
  &.open {
  padding: 10px 0;
    max-height: 400px;
  }
  a {
    width: 100%;
    color: white;
    padding: 15px 25px;
  }
}
</style>
